import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import NavbarLeft from "../navbar/NavbarLeft";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import SearchUser from "./user/SearchUser";
export default function Header() {

    const [token, setToken] = useState("");
    const [navLeft, setNavLeft] = useState(false);
    const [userData, setUserData] = useState("");
    const [serachItem, setSerachItem] = useState("");
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const formRef = useRef(null);
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (formRef.current && !formRef.current.contains(event.target)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleBlur = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.relatedTarget)) {
            return;
        }
        setDropdownVisible(false);
    };

    const navigate = useNavigate();

    let userInfo;
    if (Cookies.get("AuthUser")) {
        userInfo = JSON.parse(Cookies.get("AuthUser"));
    }

    let userToken;
    useEffect(() => {
        userToken = localStorage.getItem('AuthToken');
        if (userToken) {
            userToken = userToken.replace(/^"|"$/g, '');
            setToken(userToken);
        }
        // getUser();
    }, [userToken]);

    const handleLogout = () => {
        localStorage.removeItem('AuthToken');
        Cookies.remove("AuthUser");
        setTimeout(() => {
            navigate("/");
        }, 1000);
    }

    const handleSearch = () => {
        navigate('/search-page');
    }

    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/get-user`,
                { headers: { "Authorization": "Bearer " + token } },
            );
            setUserData(response?.data?.user);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
        getUser();
    }, [token]);

    return (
        <>
            {/* header responsive */}
            <header className="header-fixed hide-on-small show-on-large">
                <nav className="navbar navbar-expand-lg bg-body-tertiary outside-border">
                    <div className="container-fluid mx-md-5 mx-lg-5">
                        <div className="mx-4">
                            <Link to="/" className="nav-link active text-white">Social Media</Link>
                        </div>
                        {userInfo &&
                            <form ref={formRef} className="" role="search" style={{ width: "500px" }}>
                                <input
                                    className="form-control header-input"
                                    type="text"
                                    placeholder="Search here people.."
                                    aria-label="Search"
                                    onFocus={() => setDropdownVisible(true)}
                                    onBlur={handleBlur}
                                    // onBlur={() => setDropdownVisible(false)}
                                    value={serachItem}
                                    onChange={(e) => setSerachItem(e.target.value)}
                                />
                                <div ref={dropdownRef} className={`search-dropdown-content ${isDropdownVisible ? 'visible' : ''} rounded p-4`} tabIndex="-1">
                                    {isDropdownVisible &&
                                        <SearchUser userInfo={userInfo} token={token} serachItem={serachItem} setSerachItem={setSerachItem} />
                                    }
                                </div>
                            </form>
                        }
                        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button> */}
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {/* <li className="nav-item">
                                    <Link to="/" className="nav-link active">Home</Link>
                                </li> */}
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="#">Link</a>
                                </li> */}
                                {/* <li className="nav-item dropdown px-2">
                                <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown
                                </a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                    <li><a className="dropdown-item" href="#">Another action</a></li>
                                    <li><hr className="dropdown-divider" /></li>
                                    <li><a className="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </li> */}
                                {/* {userInfo &&
                                <li className="nav-item px-2">
                                    <Link to="/chat" className="nav-link" >Chat</Link>
                                </li>
                            } */}
                            </ul>

                            <ul className="navbar-nav mb-2 mb-lg-0">
                                {!userInfo &&
                                    <li className="nav-item">
                                        <Link to="/signup" className="nav-link" >Signup</Link>
                                    </li>
                                }
                                {!userInfo &&
                                    <li className="nav-item">
                                        <Link to="/signin" className="nav-link" >Login</Link>
                                    </li>
                                }
                                {/* {userInfo &&
                                    <li className="nav-item">
                                        <Link to="" className="nav-link" onClick={handleLogout} >Logout</Link>
                                    </li>
                                } */}

                                {userInfo &&
                                    <li className="nav-item dropdown px-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img className="rounded-circle" src={userData ? userData?.profile_image : ''} alt="User" width="40" height="40" />
                                            </div>
                                            <Link to="/user-profile" className="nav-link dropdown-toggle" >{userData ? userData?.username : ''}</Link>
                                        </div>
                                        <ul className="dropdown-content rounded bg-white p-0">
                                            <li>
                                                <Link to="/settings" className="dropdown-item" >Profile Settings</Link>
                                            </li>
                                            <li>
                                                {/* <a className="dropdown-item" href="#">Another action</a> */}
                                            </li>
                                            <li>
                                                {/* <a className="dropdown-item" href="#">Something else here</a> */}
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" onClick={handleLogout} >Logout</Link>
                                            </li>
                                        </ul>
                                    </li>
                                }
                            </ul>
                            {/* <form className="d-flex" role="search">
                                <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button className="btn btn-outline-success" type="submit">Search</button>
                            </form> */}
                        </div>
                    </div>
                </nav>
            </header>
            {/* header responsive */}
            <header className="header-fixed hide-on-large show-on-small">
                <nav className="navbar navbar-expand-lg bg-body-tertiary outside-border">
                    <div className="container-fluid mx-md-5 mx-lg-5">
                        <div className="" style={{ marginRight: "10px" }}>
                            <div>
                                <Link to="/" className="nav-link active text-white">Social Media</Link>
                            </div>
                            {/* <div>
                                {!navLeft &&
                                    <Link className="nav-link active text-white" onClick={() => setNavLeft(true)}>Logo</Link>
                                }
                                {navLeft &&
                                    <Link to="/" className="nav-link active text-white" onClick={() => setNavLeft(false)}>Logo</Link>
                                }
                            </div> */}
                        </div>
                        {/* {userInfo &&
                            <form ref={formRef} className="" role="search" style={{ width: "500px" }}>
                                <input
                                    className="form-control header-input"
                                    type="text"
                                    placeholder="Search here people.."
                                    aria-label="Search"
                                    onFocus={() => setDropdownVisible(true)}
                                    onBlur={handleBlur}
                                    // onBlur={() => setDropdownVisible(false)}
                                    value={serachItem}
                                    onChange={(e) => setSerachItem(e.target.value)}
                                />
                                <div ref={dropdownRef} className={`search-dropdown-content ${isDropdownVisible ? 'visible' : ''} rounded p-4`} tabIndex="-1">
                                    {isDropdownVisible &&
                                        <SearchUser userInfo={userInfo} token={token} serachItem={serachItem} setSerachItem={setSerachItem} />
                                    }
                                </div>
                            </form>
                        } */}
                        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button> */}
                        {userInfo ?
                            <>
                                <div className="navbar-toggler">
                                    <li className="nav-item dropdown px-2">
                                        <div className="d-flex align-items-center">
                                            <div className="pe-3">
                                                <svg viewBox="0 0 24 24" onClick={handleSearch} width="" height="19" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z" stroke="#9A9FBF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                            </div>
                                            <div>
                                                <img className="rounded-circle" src={userData ? userData?.profile_image : ''} width="20" height="20" />
                                            </div>
                                            <Link to="/user-profile" className="nav-link dropdown-toggle px-1" >{userData ? userData?.username : ''}</Link>
                                        </div>
                                        <ul className="dropdown-content rounded bg-white p-0">
                                            <li>
                                                <Link to="" className="dropdown-item" >Profile Settings</Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" onClick={handleLogout} >Logout</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </div>
                            </>
                            :
                            <div className="navbar-toggler">
                                <ul className="navbar-nav d-flex flex-row">
                                    {!userInfo &&
                                        <li className="nav-item pe-2">
                                            <Link to="/signup" className="nav-link">Signup</Link>
                                        </li>
                                    }
                                    {!userInfo &&
                                        <li className="nav-item">
                                            <Link to="/signin" className="nav-link">Login</Link>
                                        </li>
                                    }
                                </ul>
                            </div>
                        }
                    </div>
                </nav>
            </header>
            {/* {userInfo &&
                <NavbarLeft navLeft={navLeft} setNavLeft={setNavLeft} />
            } */}
        </>
    )
}