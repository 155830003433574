import React, { useEffect, useState } from "react";
import Header from "../Header";
import FriendCoverPage from "./FriendCoverPage";
import { useParams } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import AboutFriend from "./AboutFriend";
import FriendFeeds from "./FriendFeeds";
import FriendPhotos from "./FriendPhotos";

export default function FriendProfile() {

    const [userDetails, setUserDetails] = useState("");
    const [friendCoverImg, setFriendCoverImg] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        // console.log(id, 'param');
    }, [id]);


    const getSingleUser = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/get-single-user`,
                { user_id: id }
            );
            setUserDetails(response?.data?.user);
            setFriendCoverImg(response?.data?.user?.cover_image);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
        getSingleUser();
    }, [id]);


    return (
        <>
            <Header />
            <div className="container top-space">
                <div className="user-banner">
                    <FriendCoverPage id={id} userDetails={userDetails} friendCoverImg={friendCoverImg} />

                    <section className="">
                        <div className="profile-section-desktop hide-on-small show-on-large">
                            <div className="p-4" style={{ backgroundColor: "#fff", borderRadius: "5px", height: "90px" }}>
                            </div>
                            <div className="profile-section">
                                <div className="">
                                    {userDetails?.profile_image &&
                                        <img src={userDetails?.profile_image} className="rounded-circle" alt="img" width={120} height={120} />
                                    }
                                </div>
                                <h4 className="m-0">{userDetails?.username}</h4>
                            </div>
                        </div>
                        {/* <div className="p-4 bg-white rounded mt-2">
                            <div className="row">
                                <div className="col-3">
                                    <div className="">
                                        <h6 className="m-0">Friends (25)</h6>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="ms-3">
                                        <h6 className="m-0">Posts (11)</h6>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="ms-3">
                                        sdfsdf
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="ms-3">
                                        sdfsdf
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className=" mt-2">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <AboutFriend userDetails={userDetails} />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                    <FriendFeeds userDetails={userDetails}/>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                                    <FriendPhotos/>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}