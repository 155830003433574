import React from "react";
import Moment from "moment";

export default function AboutFriend({userDetails}) {

    return (
        <div className="bg-white rounded">
            <div className="p-4 border-bottom">
                <h6 className="m-0">Personal Info</h6>
            </div>
            <div className="p-4">
                <ul className="p-0 m-0" style={{listStyle: "none"}}>
                    <li className="d-flex">
                        <span className="title friend-about-list">About:</span>
                        <span className="text">{userDetails?.about ? userDetails?.about : ""}</span>
                    </li>
                    <li className="my-3 d-flex">
                        <span className="title friend-about-list">Gender:</span>
                        <span className="text">{userDetails?.gender ? userDetails?.gender : ""}</span>
                    </li>
                    <li className="my-3 d-flex">
                        <span className="title friend-about-list">Joined:</span>
                        <span className="text">{userDetails?.createdAt ? Moment(userDetails?.createdAt).format('DD-MM-YYYY') : ""}</span>
                    </li>
                    <li className="my-3 d-flex">
                        <span className="title friend-about-list">Birthday:</span>
                        <span className="text">{userDetails?.dob ? Moment(userDetails?.dob).format('DD-MM-YYYY') : ""}</span>
                    </li>
                    <li className="my-3 d-flex">
                        <span className="title friend-about-list">Phone Number:</span>
                        <span className="text">{userDetails?.phone ? userDetails?.phone : ""}</span>
                    </li>
                    <li className="my-3 d-flex">
                        <span className="title friend-about-list">Email:</span>
                        <span className="text">{userDetails?.email ? userDetails?.email : ""}</span>
                    </li>
                    <li className="my-3 d-flex">
                        <span className="title friend-about-list">Lives in:</span>
                        <span className="text">{userDetails?.address ? userDetails?.address : ""}</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}