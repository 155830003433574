import React, { useState } from "react";
import axios from "axios";
import { Toaster, toast } from 'react-hot-toast';

export default function ChangePass({ token }) {

    const [allValue, setAllValue] = useState({ oldPassword: "", newPassword: "", confirmPassword: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAllValue({ ...allValue, [name]: value });
    }

    const updatePassword = (e) => {
        e.preventDefault();
        let save = true;
        if (!allValue?.oldPassword) {
            save = false;
            toast.error("please enter oldPassword !");
        } else if (!allValue?.newPassword) {
            save = false;
            toast.error("please enter newPassword !");
        } else if (!allValue?.confirmPassword) {
            save = false;
            toast.error("please enter confirmPassword !");
        } else if (allValue?.confirmPassword !== allValue?.newPassword) {
            save = false;
            toast.error("newPassword and confirmPassword dos'nt match !");
        }
        if (save) {
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/change-password`,
                allValue,
                { headers: { "Authorization": "Bearer " + token } },
            ).then((response) => {
                if (response) {
                    setAllValue({ oldPassword: "", newPassword: "", confirmPassword: "" });
                    toast.success(response?.data?.message || "Your password change successful!");
                }
            }).catch((error) => {
                toast.error(error?.response?.data?.message || "Something went wrong!");
            });
        }
    }

    return (
        <div className="d-lg-flex d-md-flex justify-content-between mt-2 bg-white rounded">
            <div className="col-12">
                <div className="p-4 border-bottom">
                    <h6 className="m-0">Change Password</h6>
                </div>
                <div className="p-4">
                    <form>
                        <div className="row">
                            <div className="mb-3 col-12">
                                <label className="form-label title">Old Password</label>
                                <input type="text" className="form-control" value={allValue.oldPassword} name="oldPassword" onChange={handleChange} placeholder="Old Password"></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-12">
                                <label className="form-label title">New Password</label>
                                <input type="text" className="form-control" value={allValue.newPassword} name="newPassword" onChange={handleChange} placeholder="New Password"></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 col-12">
                                <label className="form-label title">Confirm Password</label>
                                <input type="text" className="form-control" value={allValue.confirmPassword} name="confirmPassword" onChange={handleChange} placeholder="Confirm Password"></input>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="btn btn-theam" onClick={updatePassword}>Update Password</button>
                        </div>
                    </form>
                </div>
            </div>
            <Toaster />
        </div>
    )
}