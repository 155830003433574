import React from 'react';
import GridBox from './GridBox';
import { Apple } from './AllApples';

const AppleGrid = ({ grid, onDrop, onRemove }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '320px' }}>
            {grid.map((appleId, index) => (
                <GridBox
                    key={index}
                    id={index}
                    onDrop={onDrop}
                    onRemove={onRemove}
                >
                    {appleId && <Apple id={appleId} isDropped />}
                </GridBox>
            ))}
        </div>
    );
};

export default AppleGrid;
