import DragDrop from './components/DragNDrop/DragDrop';
import FeedContainer from './components/feed/userfeed/FeedContainer';
import FriendProfile from './components/friend/FriendProfile';
import Signin from './components/Signin';
import Signup from './components/Signup';
import About from './components/user/About';
import Home from './components/user/Home';
import ProfileSettings from './components/user/profile/ProfileSettings';
import Setting from './components/user/profile/Setting';
import SearchPage from './components/user/search/SearchPage';
import SearchUser from './components/user/SearchUser';
import VerifyEmail from './components/VerifyEmail';
import logo from './logo.svg';
import View from './View';
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import './App.css';

function App() {
  return (
    <div className="App bg-gry">
      <BrowserRouter>
        <Routes>
          <Route exct path="/" element={<View />}></Route>
          <Route exct path='/user-profile' element={<Home/>}></Route>
          <Route exct path='/signup' element={<Signup/>}></Route>
          <Route exct path='/signin' element={<Signin/>}></Route>
          <Route exct path='/verify-email/:id' element={<VerifyEmail/>}></Route>
          <Route exct path='/about' element={<About/>}></Route>
          <Route exct path='/search-user' element={<SearchUser/>}></Route>
          <Route exct path='/profile-setting' element={<ProfileSettings/>}></Route>
          <Route exct path='/friend-profile/:id' element={<FriendProfile/>} ></Route>
          <Route exct path='/search-page' element={<SearchPage/>}></Route>
          <Route exct path='/settings' element={<Setting/>}></Route>
          <Route exct path='/create-feed' element={<FeedContainer/>}></Route>
          <Route exct path='/drag-drop' element={<DragDrop/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
