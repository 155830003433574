import React from "react";
import coverImage from "../../assets/img/top-header1.webp";
import { Link } from "react-router-dom";

export default function FriendCoverPage({ id, userDetails, friendCoverImg }) {

    return (
        <div className="conver-img d-flex align-items-end justify-content-between p-3" style={{ backgroundImage: `url(${friendCoverImg ? friendCoverImg : ''})` }}>
            <div style={{ position: "absolute", top: 10, zIndex: "11" }}>
                    <Link to="/user-profile">
                    <svg viewBox="0 0 1024 1024" width="25px" height="30px" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#fff" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path><path fill="#fff" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"></path></g></svg>
                    </Link>
                </div>
            <div className="user-info">
                <div className="show-on-small hide-on-large">
                    <img src={friendCoverImg ? friendCoverImg : ""} alt="img" className="rounded-circle" width={80} height={80} />
                    <h4 style={{ color: "#fff", margin: "unset" }}>{userDetails?.username}</h4>
                </div>
            </div>
        </div>
    )
}