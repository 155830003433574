import React from 'react';
import AllApples from './AllApples';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { isMobile } from 'react-device-detect';

const DragDrop = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5rem", marginTop: "20rem" }}>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <AllApples />
            </DndProvider>
        </div>
    );
};

export default DragDrop;
