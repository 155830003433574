import React, { useState } from "react";
import user_icon from "../../../assets/img/user_icon.png";
import AddFeedModal from "./AddFeedModal";

export default function UserFeedComponent({ userInfo, token, user, getOwnFriendsPostCall }) {

    const [isAddFeedModal, setIsAddFeedModal] = useState(false);

    const openModal = () => {
        setIsAddFeedModal(true);
    };

    return (
        <div className="bg-white rounded">
            <div className="p-2 border-bottom">
                <div className="d-flex align-items-center">
                    <img src={user?.profile_image ? user?.profile_image : user_icon} alt="img" className="rounded-circle" width={user?.profile_image ? 40 : 45} height={40} />
                    <input
                        className="form-control mx-2"
                        type="text"
                        placeholder="Start a post.."
                        aria-label="Search"
                        onClick={openModal}
                    />
                    <button className="btn m-0 p-0" onClick={openModal}>
                        <svg fill="#9A9FBF" width={35} height={35} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#9A9FBF"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path><path d="m12 12-1-1-2 3h10l-4-6z"></path></g></svg>
                    </button>
                </div>

                {/* <button onClick={openModal}>
                    Open the modal
                </button> */}

                <AddFeedModal token={token} modalOpen={isAddFeedModal} setModalOpen={setIsAddFeedModal} getOwnFriendsPostCall={getOwnFriendsPostCall} />
            </div>
        </div>
    )
}