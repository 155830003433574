import React, { useState } from "react";
import Header from "./Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { Toaster, toast } from 'react-hot-toast';

export default function Signup() {

    const [allValue, setAllValue] = useState({ firstname: "", lastname: "", email: "", password: "" });

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        setAllValue({ ...allValue, [name]: value });
    }

    const validateEmail = (input) => {
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailPattern.test(input);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let save = true;
        if (!allValue.firstname) {
            save = false;
            toast.error("please enter your first name !");
        } else if (!allValue.lastname) {
            save = false;
            toast.error("please enter your last name !");
        } else if (!allValue.email) {
            save = false;
            toast.error("please enter your email !");
        } else if (!validateEmail(allValue.email)) {
            save = false;
            toast.error("please provide valid email");
        } else if (!allValue.password) {
            save = false;
            toast.error("please enter your password !");
        }
        if (save) {
            var Data = { firstname: allValue.firstname, lastname: allValue.lastname, email: allValue.email, password: allValue.password, };
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/email-verification`, Data)
                .then((response) => {
                    if (response) {
                        setAllValue({ firstname: "", lastname: "", email: "", password: "" });
                        toast.success(response?.data?.message || "Please check your email to verify your account!");                        
                    }
                }).catch((error) => {
                    toast.error(error?.response?.data?.message || "Something went wrong!");
                });
        }
    }

    return (
        <>
            <Header />

            <div className="container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div className="signup-box bg-white">
                    <div className="text-center pb-4">
                        <h4>Sign Up</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="mb-3 col-lg-6">
                                <label className="form-label">First Name</label>
                                <input type="text" className="form-control" name="firstname" value={allValue.firstname} onChange={handleOnChange} placeholder="First Name"></input>
                            </div>
                            <div className="mb-3 col-lg-6">
                                <label className="form-label">Last Name</label>
                                <input type="text" className="form-control" name="lastname" value={allValue.lastname} onChange={handleOnChange} placeholder="Last Name"></input>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control" name="email" value={allValue.email} onChange={handleOnChange} placeholder="Email" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input type="password" className="form-control" name="password" value={allValue.password} onChange={handleOnChange} placeholder="password" />
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-theam form-control">Sign up</button>
                        </div>
                    </form>
                </div>
                <Toaster/>
            </div>
        </>
    )
}