import React, { useEffect, useState } from "react";
import Header from "../../Header";
import ProfileSettings from "./ProfileSettings";
import Cookies from "js-cookie";

export default function Setting() {

    const [token, setToken] = useState("");

    let userInfo;
    if (Cookies.get("AuthUser")) {
        userInfo = JSON.parse(Cookies.get("AuthUser"));
    }

    let userToken;
    useEffect(() => {
        userToken = localStorage.getItem('AuthToken');
        if (userToken) {
            userToken = userToken.replace(/^"|"$/g, '');
            setToken(userToken);
        }
    }, [userToken]);

    return(
        <>
        <Header/>
        <div className="container top-space">
            <ProfileSettings userInfo={userInfo} token={token}/>
        </div>
        </>
    )
}