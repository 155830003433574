import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";

export default function AddFeedModal({ token, modalOpen, setModalOpen, getOwnFriendsPostCall }) {

    const [feedImg, setFeedImg] = useState({ item: "" });
    const [feedImgSend, setFeedImgSend] = useState({ item: "" });
    const [postTitle, setPostTitle] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    const [preview, setPreview] = useState(null);

    const textareaRef = useRef(null);

    const closeModal = () => {
        setPostTitle("");
        setFeedImg({ item: "" });
        setIsDisabled(true);
        setModalOpen(false);
    };

    const handleFeedContent = (e) => {
        const text = e.target.value;
        setPostTitle(text);
        setIsDisabled(false);
    }

    const handleFeedFile = (event) => {
        const profileFile = event.target.files[0];
        if (profileFile) {
            setFeedImg(URL.createObjectURL(profileFile));
            setFeedImgSend(profileFile);
            setIsDisabled(false);
        }
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    }, [modalOpen]);

    const createPost = async () => {
        try {
            const formData = new FormData();
            formData.append('item', feedImgSend);
            formData.append('title', postTitle);

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/post/add-post`,
                formData,
                { headers: { "Authorization": "Bearer " + token } },
            );
            setIsDisabled(true);
            closeModal();
            getOwnFriendsPostCall();
        } catch (error) {
            toast.error('failed.');
        }
    }

    const renderPreview = () => {
        const fileType = feedImgSend?.type;

        if (fileType?.startsWith('image/')) {
            return setPreview(<img src={feedImg} alt="Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />);
        } else if (fileType?.startsWith('video/')) {
            return setPreview(<video src={feedImg} controls autoPlay muted style={{ maxWidth: '100%', maxHeight: '200px' }} />);
        }
        return <p>Unsupported file type</p>;
    };
    useEffect(() => {
        if (feedImgSend && feedImg) {
            renderPreview();
        }
    }, [feedImgSend, feedImg]);

    return (
        <div>
            {modalOpen && (
                <div className="overlay">
                    <dialog id="modal" open>
                        <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
                            <div>

                            </div>
                            <div>
                                <h4 className="m-0">Create feed</h4>
                            </div>
                            <div>
                                <button className="btn m-0 p-0" onClick={closeModal}>
                                    <svg viewBox="0 0 24 24" fill="none" width={40} height={40} xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 8L8 16M8.00001 8L16 16" stroke="#9a9fbf" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                                </button>
                            </div>
                        </div>
                        <div className="">
                            <div className="pt-2 feed-input">
                                <textarea name="title" rows={4} ref={textareaRef} value={postTitle} className="focus-disable w-100" onChange={(e) => handleFeedContent(e)} style={{ border: "none", resize: "none" }} placeholder="Write somthing here..."></textarea>
                            </div>

                            {/* {feedImg.item != "" &&
                                <div style={{ overflow: "hidden", textAlign: "center" }}>
                                    <img src={feedImg ? feedImg : ""} alt="" width="auto" height={200} />
                                </div>
                            } */}
                            <div style={{ overflow: "hidden", textAlign: "center" }}>
                                {preview ?
                                    preview
                                    :
                                    ''
                                }
                            </div>
                            <div className="my-4">
                                <div className="d-flex align-items-center justify-content-between border p-2">
                                    <h6 className="m-0">Add to your post</h6>
                                    <div className="cover-image-upload">
                                        <label htmlFor="file-input">
                                            <svg fill="#9A9FBF" width={30} height={30} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#9A9FBF"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M20 2H8c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM8 16V4h12l.002 12H8z"></path><path d="M4 8H2v12c0 1.103.897 2 2 2h12v-2H4V8z"></path><path d="m12 12-1-1-2 3h10l-4-6z"></path></g></svg>
                                        </label>
                                        <input className="form-label" id="file-input" type="file" name="item" onChange={handleFeedFile} accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" />
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <button disabled={isDisabled} onClick={createPost} className="btn btn-theam m-0 w-100">
                                    Post
                                </button>
                            </div>
                        </div>
                    </dialog>
                </div>
            )}
        </div>
    )
}