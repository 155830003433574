import React, { useEffect, useState } from "react";
import Header from "../Header";
import user_icon from "../../assets/img/user_icon.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

export default function SearchUser({ userInfo, token, serachItem, setSerachItem }) {

    const [allUsers, setAllUsers] = useState("");
    // const [serachItem, setSerachItem] = useState("");
    const [isDisabled, setIsDisabled] = useState({});
    const [addFriendButtonName, setAddFriendButtonName] = useState({});
    const [acceptButtonName, setAcceptButtonName] = useState({});
    const [isAcceptDisabled, setIsAcceptDisabled] = useState({});

    const userList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/user-list`,
                {
                    headers: { "Authorization": "Bearer " + token },
                    params: { search: serachItem }
                }
            );
            setAllUsers(response?.data);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
        userList();
    }, [serachItem]);

    const sendFriendRequest = async (e, receiverID) => {
        e.preventDefault();
        try {
            var Data = { user_id: userInfo?.data?._id, receiver: receiverID, };
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/send-friend-request`,
                Data,
                { headers: { "Authorization": "Bearer " + token } },
            );
            // toast.success(response?.data?.message || 'Request sent.');
            setIsDisabled(prevState => ({ ...prevState, [receiverID]: true }));
            setAddFriendButtonName(prevState => ({ ...prevState, [receiverID]: "Pending..." }));
            return
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    const acceptFriendRequest = async (e, senderID, status) => {
        e.preventDefault();
        try {
            var Data = { sender: senderID, status: status };
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/accept-reject-request`,
                Data,
                { headers: { "Authorization": "Bearer " + token } },
            );            
            setIsAcceptDisabled(prevState => ({ ...prevState, [senderID]: true }));
            setAcceptButtonName(prevState => ({ ...prevState, [senderID]: "Accepted" }));
            return
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    return (
        <>
            {/* <Header /> */}
            <div className="">
                {/* <div>
                    <form className="d-flex" role="search">
                        <input className="form-control me-2" type="search" value={serachItem} onChange={(e) => setSerachItem(e.target.value)} placeholder="Search User.." aria-label="Search" />
                    </form>
                </div> */}
                <div className="">
                    <div>
                        {!serachItem && allUsers?.result?.length <= 0 &&
                            <div className="text-center">
                                <h6>Find Friend..</h6>
                            </div>
                        }
                        {serachItem && allUsers?.result?.length <= 0 &&
                            <div className="text-center">
                                <h6>No Friend Found..</h6>
                            </div>
                        }
                        <ul className="p-0" style={{ listStyle: "none" }}>
                            {allUsers?.result?.map((val, i) => {
                                return (
                                    <li className="my-3">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <img src={val?.profile_image ? val?.profile_image : user_icon} className="rounded-circle" alt="User" width={`${val?.profile_image ? '70' : '80'}`} height="70" />
                                                {/* <h6 className="mb-4">{val?.username}</h6> */}
                                                <Link to={`/friend-profile/${val?._id}`} style={{ textDecoration: "none" }}><h6 className={`mb-4 hover-color ${val?.profile_image ? 'ms-2' : ''} `}>{val?.username}</h6></Link>
                                            </div>
                                            <div className="mx-2">
                                                {val?.friendStatus === 'addfriend' &&
                                                    <button className="btn btn-theam btn-fixed-width" disabled={isDisabled[val?._id]} onClick={(e) => sendFriendRequest(e, val?._id)}>{addFriendButtonName[val?._id] || "Add Friend"}</button>
                                                }
                                                {val?.friendStatus === 'pending' &&
                                                    <button className="btn btn-theam btn-fixed-width" disabled>Pending...</button>
                                                }
                                                {val?.friendStatus === 'accepted' &&
                                                    <button className="btn btn-theam btn-fixed-width" disabled>Accepted</button>
                                                }
                                                {val?.friendStatus === 'accept' &&
                                                    <button className="btn btn-theam btn-fixed-width" disabled={isAcceptDisabled[val?._id]} onClick={(e) => acceptFriendRequest(e, val?._id, true)}>{acceptButtonName[val?._id] || "Accept"}</button>
                                                }
                                                {/* <div className="d-flex align-items-center">
                                                    <div className="pt-2">
                                                        <button className="btn btn-theam small-text" disabled={isDisabled[val?._id]} onClick={() => sendFriendRequest(val?._id)}>Add Friend</button>
                                                    </div>
                                                    <div className="pt-2 mx-2">
                                                        <button className="btn btn-theam" disabled>View</button>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
            <Toaster />
        </>
    )
}