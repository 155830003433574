import React, { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Home from "./components/user/Home";
import { useNavigate } from "react-router-dom";
import AllFeed from "./components/feed/AllFeed";
import UserFeedComponent from "./components/feed/userfeed/UserFeedComponent";
import Cookies from "js-cookie";
import axios from "axios";
import toast from "react-hot-toast";

export default function View() {

    const navigate = useNavigate();
    const [token, setToken] = useState("");
    const [allPosts, setAllPosts] = useState("");
    const [user, setUser] = useState("");

    let userToken;
    useEffect(() => {
        userToken = localStorage.getItem('AuthToken');
        if (!userToken) {
            navigate("/signin");
        } else if (userToken) {
            userToken = userToken.replace(/^"|"$/g, '');
            setToken(userToken);
        }
    }, [userToken]);

    let userInfo;
    if (Cookies.get("AuthUser")) {
        userInfo = JSON.parse(Cookies.get("AuthUser"));
    }

    useEffect(() => {
        if (!userInfo) {
            navigate('/signin');
        }
    });

    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/get-user`,
                { headers: { "Authorization": "Bearer " + token } },
            );
            setUser(response?.data?.user);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
        getUser();
    }, [token]);

    const getOwnFriendsPost = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/post/get-own-friends-post`,
                { headers: { "Authorization": "Bearer " + token } },
            );
            setAllPosts(response?.data);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
        getOwnFriendsPost();
    }, [token]);

    return (
        <>
            {/* <Sidebar></Sidebar> */}
            <Header />
            <div className="container top-space">
                {/* <Home token={token}/> */}
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 mb-2 d-none d-md-block d-lg-block">
                        <div className="bg-white rounded p-4">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                        <UserFeedComponent userInfo={userInfo} token={token} user={user} getOwnFriendsPostCall={getOwnFriendsPost} />
                        <AllFeed token={token} allPosts={allPosts} user={user} getOwnFriendsPostCall={getOwnFriendsPost} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-12 mt-2 mt-sm-2 mt-lg-0 mt-md-0 d-none d-md-block d-lg-block">
                        <div className="bg-white rounded p-4">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}