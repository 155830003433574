import React, { useState, useEffect } from 'react';
import AppleGrid from './AppleGrid';
import { useDrag } from 'react-dnd';

const initialApples = ["apple1", "apple2", "apple3", "apple4"];
const initialGrid = Array(9).fill(null);

const AllApples = () => {
    const [availableApples, setAvailableApples] = useState(initialApples);
    const [grid, setGrid] = useState(initialGrid);

    useEffect(() => {
        const savedApples = localStorage.getItem('availableApples');
        const savedGrid = localStorage.getItem('appleGrid');
        if (savedApples) setAvailableApples(JSON.parse(savedApples));
        if (savedGrid) setGrid(JSON.parse(savedGrid));
    }, []);

    const handleAppleUsed = (appleId) => {
        setAvailableApples((prevApples) => prevApples.filter((apple) => apple !== appleId));
    };

    const handleAppleRemove = (boxId) => {
        const appleId = grid[boxId];
        if (appleId) {
            setGrid((prevGrid) => {
                const newGrid = [...prevGrid];
                newGrid[boxId] = null;
                return newGrid;
            });
            setAvailableApples((prevApples) => [...prevApples, appleId]);
        }
    };

    const handleDrop = (item, boxId) => {
        if (!grid[boxId] && availableApples.includes(item.id)) {
            const newGrid = [...grid];
            newGrid[boxId] = item.id;
            setGrid(newGrid);
            handleAppleUsed(item.id);
        }
    };

    const saveToStorage = () => {
        localStorage.setItem('availableApples', JSON.stringify(availableApples));
        localStorage.setItem('appleGrid', JSON.stringify(grid));
        alert("saved!!");
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
                {availableApples.map((id) => (
                    <Apple key={id} id={id} />
                ))}
            </div>
            <AppleGrid grid={grid} onDrop={handleDrop} onRemove={handleAppleRemove} />
            <button onClick={saveToStorage}>Save</button>
        </div>
    );
};

export default AllApples;


export const Apple = ({ id, isDropped = false }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'APPLE',
        item: { id },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [id]);

    return (
        <div
            ref={!isDropped ? drag : null}
            style={{
                opacity: isDragging ? 0.5 : 1,
                fontSize: 25,
                fontWeight: 'bold',
                cursor: isDropped ? 'default' : 'pointer',
            }}
        >
            🍎
        </div>
    );
};
