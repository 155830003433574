import React from 'react';
import { useDrop } from 'react-dnd';

const GridBox = ({ id, onDrop, children, onRemove }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'APPLE',
        drop: (item) => onDrop(item, id),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [id, onDrop]);

    return (
        <div
            ref={drop}
            style={{
                height: '100px',
                width: '100px',
                border: '1px solid black',
                backgroundColor: isOver ? 'skyblue' : 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {children}
            {children && (
                <button
                    onClick={() => onRemove(id)}
                    style={{
                        position: 'relative',
                        top: '-10px',
                        left: '-10px',
                        color: 'red',
                        border: 'none',
                        cursor: 'pointer',
                        backgroundColor: 'transparent'
                    }}
                >
                    <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em">
                        <path
                            fill="currentColor"
                            fillRule="evenodd"
                            d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            )}
        </div>
    );
};

export default GridBox;
