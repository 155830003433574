import React, { useEffect, useState } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import user_icon from "../../assets/img/user_icon.png";
import coverImage from "../../assets/img/top-header1.webp";
import { Link } from "react-router-dom";

// export default function CoverPage({ userInfo, coverImg, profileImg }) {
export default function CoverPage({ userInfo, token }) {
    // const [coverImg, setCoverImg] = useState(null);
    const [coverImg, setCoverImg] = useState({ cover_image: "" });
    const [profileImg, setProfileImg] = useState(null);
    const [coverImgbtn, setCoverImgbtn] = useState(false);
    const [cvImg, setCv] = useState({ cover_image: "" });
    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/get-user`,
                { headers: { "Authorization": "Bearer " + token } },
            );
            // setUserData(response?.data?.user);
            // console.log(response?.data, '7777777778888888')
            setProfileImg(response?.data?.user?.profile_image);
            setCoverImg(response?.data?.user?.cover_image);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }
    useEffect(() => {
        getUser();
    }, [token]);
    const handleCoverFile = (event) => {
        const profileFile = event.target.files[0]
        if (profileFile) {
            setCoverImgbtn(true);
            // setCoverImg(profileFile);
            setCoverImg(URL.createObjectURL(profileFile));
            setCv(profileFile);
        }
    }
    const updateCoverImage = async () => {
        try {
            const formData = new FormData();
            formData.append('cover_image', cvImg);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update-cover-image`,
                formData,
                { headers: { "Authorization": "Bearer " + token } },
            );
            toast.success(response?.data?.message || "Successfuly Updated.");
            getUser();
            setTimeout(() => {
                setCoverImgbtn(false);
            }, 100);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    const handleDiscard = () => {
        getUser();
        setCoverImgbtn(false);
    }

    return (
        <>
            {/* <div style={{ marginBottom: "-40px", position: "relative", zIndex: "111111" }}>
                back button
            </div> */}
            <div className="conver-img d-flex align-items-end justify-content-between p-3" style={{ backgroundImage: `url(${coverImg ? coverImg : ''})` }}>
                <div style={{ position: "absolute", top: 10, zIndex: "11" }}>
                    <Link to="/">
                    <svg viewBox="0 0 1024 1024" width="25px" height="30px" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#fff" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path><path fill="#fff" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"></path></g></svg>
                    </Link>
                </div>
                <div className="user-info">
                    <div className="show-on-small hide-on-large">
                        <img src={profileImg ? profileImg : ""} alt="" className="rounded-circle" width={80} height={80} />
                        <h4 style={{ color: "#fff", margin: "unset" }}>{userInfo?.data?.username}</h4>
                    </div>
                </div>
                <div className="user-info" style={{ textAlign: "center" }}>
                    {!coverImgbtn &&
                        <div className="cover-image-upload">
                            <label htmlFor="file-input">
                                <i className="fas fa-camera" style={{ backgroundColor: "#d7d7d7", borderRadius: "100px", padding: "5px" }}></i>
                            </label>
                            <input className="form-label" id="file-input" onChange={handleCoverFile} type="file" name="cover_image" accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" />
                        </div>
                    }
                    {coverImgbtn &&
                        // <button className="btn btn-theam mt-1" onClick={updateCoverImage}>Save</button>
                        <div className="">
                            <div>
                                {/* <img src={cvImg} alt="Preview" style={{ width: '100%', height: 'auto', maxHeight: '500px', objectFit: 'cover' }} /> */}
                            </div>
                            <button type="button" className="btn btn-theam px-2 py-1" onClick={updateCoverImage}>Upload</button>
                            <button type="button" className="btn btn-danger px-2 py-1" onClick={handleDiscard}>Cancel</button>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}