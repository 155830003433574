import React from "react";
import coverImage from "../../assets/img/top-header1.webp";

export default function FriendPhotos() {

    return (
        <div className="bg-white rounded">
            <div className="p-4 border-bottom">
                <h6 className="m-0">Latest Photos</h6>
            </div>
            <div className="p-4">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                        <div>
                            <img src={coverImage} alt="" width={80} height={80} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}