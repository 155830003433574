import axios from "axios";
import React, { useEffect } from "react";
import { Toaster, toast } from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";

export default function VerifyEmail() {

    const { id } = useParams();

    useEffect(() => {
        console.log(id, 'param')
        verify_email(id);
    }, [id]);

    const navigate = useNavigate();

    const verify_email = async(token) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/user-register/${token}`);
            toast.success(response.data.message);
            navigate('/signin');
          } catch (error) {
            toast.error(error.response.data.message || 'Sign in failed.');
          }
    }

    return (
        <div className="container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div className="bg-white">
                <div className="text-center">
                    <h4>Verifing Email</h4>
                    <h6>Please wait</h6>
                </div>
            </div>
            <Toaster />
        </div>
    )
}