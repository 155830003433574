import React from "react";

export default function ProfileIntro({ user }) {
    return (
        <div className="bg-white rounded">
            <div className="p-4 border-bottom">
                <h6 className="m-0">Profile Intro</h6>
            </div>
            <div className="p-4">
                <div>
                    <ul className="p-0 m-0" style={{ listStyle: "none" }}>
                        <li className="mb-4">
                            <span className="title">About Me:</span><br />
                            <span className="text">{user?.about}</span>
                        </li>
                        <li className="mb-4">
                            <span className="title">Name:</span><br />
                            <span className="text">{user?.username}</span>
                        </li>
                        <li>
                            <span className="title">Gender:</span><br />
                            <span className="text">{user?.gender}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}