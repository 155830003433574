import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import requestIcon from "../../assets/img/request-icon.png";
import friendsIcon from "../../assets/img/friends-icon.png";
import searchIcon from "../../assets/img/search-icon.png";
import aboutIcon from "../../assets/img/profile-icon.png";
import postIcon from "../../assets/img/post-icon.png";
import CoverPage from "./CoverPage";
import SearchUser from "./SearchUser";
import About from "./About";
import Cookies from "js-cookie";
import FriendRequest from "./FriendRequest";
import Posts from "./Posts";
import FriendList from "./FriendList";
import Profile from "./Profile";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ProfileIntro from "./profile/ProfileIntro";
import Header from "../Header";
import Timeline from "./timeline/Timeline";
import ProfileSettings from "./profile/ProfileSettings";

// export default function Home({ token }) {
export default function Home() {

    const [token, setToken] = useState("");

    let userInfo;
    if (Cookies.get("AuthUser")) {
        userInfo = JSON.parse(Cookies.get("AuthUser"));
    }

    const navigate = useNavigate();

    let userToken;
    useEffect(() => {
        userToken = localStorage.getItem('AuthToken');
        if (!userToken) {
            navigate("/signin");
        } else if (userToken) {
            userToken = userToken.replace(/^"|"$/g, '');
            setToken(userToken);
        }
    }, [userToken]);

    const [coverImg, setCoverImg] = useState(null);
    // const [profileImg, setProfileImg] = useState(null);
    const [timeline, setTimeline] = useState(true);
    const [about, setAbout] = useState(false);
    const [friendsList, setFriendsList] = useState(false);
    const [requests, setRequests] = useState(false);
    const [posts, setPosts] = useState(false);
    const [serach, setSerach] = useState(false);
    const [profileSetting, setProfileSetting] = useState(false);
    const [activeTab, setActiveTab] = useState(false);
    const [userName, setUserName] = useState("");
    const [user, setUser] = useState("");
    const [profileImg, setProfileImg] = useState({ profile_image: "" });
    const [profileImgbtn, setProfileImgbtn] = useState(false);
    const [profileImgPreview, setProfileImgPreview] = useState(null);

    useEffect(() => {
        if (!userInfo) {
            navigate('/signin');
        }
    });

    const setPage = (page) => {
        setActiveTab(page);
        switch (page) {
            case "timeline":
                setTimeline(true);
                setAbout(false);
                setFriendsList(false);
                setRequests(false);
                setPosts(false);
                setSerach(false);
                setProfileSetting(false);
                break;
            case "about":
                setTimeline(false);
                setAbout(true);
                setFriendsList(false);
                setRequests(false);
                setPosts(false);
                setSerach(false);
                setProfileSetting(false);
                break;
            case "request":
                setTimeline(false);
                setAbout(false);
                setFriendsList(false);
                setRequests(true);
                setPosts(false);
                setSerach(false);
                setProfileSetting(false);
                break;
            case "post":
                setTimeline(false);
                setAbout(false);
                setFriendsList(false);
                setRequests(false);
                setPosts(true);
                setSerach(false);
                setProfileSetting(false);
                break;
            case "search":
                setTimeline(false);
                setAbout(false);
                setFriendsList(false);
                setRequests(false);
                setPosts(false);
                setSerach(true);
                setProfileSetting(false);
                break;
            case "friends":
                setTimeline(false);
                setAbout(false);
                setFriendsList(true);
                setRequests(false);
                setPosts(false);
                setSerach(false);
                setProfileSetting(false);
                break;
            case "profileSetting":
                setTimeline(false);
                setAbout(false);
                setFriendsList(false);
                setRequests(false);
                setPosts(false);
                setSerach(false);
                setProfileSetting(true);
                break;
        }
    }

    useEffect(() => {
        if (timeline) {
            setActiveTab("timeline");
        }
    });

    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/get-user`,
                { headers: { "Authorization": "Bearer " + token } },
            );
            setUserName(response?.data?.user?.username);
            setProfileImg(response?.data?.user?.profile_image);
            setUser(response?.data?.user);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
        getUser();
    }, [token]);

    const handleProfileFile = (event) => {
        const profileFile = event.target.files[0]
        if (profileFile) {
            setProfileImgbtn(true);
            // setProfileImg(URL.createObjectURL(profileFile));
            setProfileImg(profileFile);
            setProfileImgPreview(URL.createObjectURL(profileFile));
        }
    }

    const updateProfileImage = async () => {
        console.log('i am here')
        try {
            const formData = new FormData();
            formData.append('profile_image', profileImg);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update-profile-image`,
                formData,
                { headers: { "Authorization": "Bearer " + token } },
            );
            toast.success(response?.data?.message || "Successfuly Updated.");
            getUser();
            setTimeout(() => {
                setProfileImgbtn(false);
            }, 100);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    const handleDiscardImg = () => {
        getUser();
        setProfileImgbtn(false);
    }

    return (
        <>
            <Header />
            <div className="container top-space" style={{ position: "relative" }}>
                <div className="user-banner">
                    <CoverPage userInfo={userInfo} token={token} coverImg={coverImg} profileImg={profileImg} />

                    <section className="">
                        <div className="profile-section-desktop">
                            <div className="menus-items p-4">
                                {/* only for mobile responsive */}
                                <ul className="menu-items-list d-flex justify-content-between p-0 m-0 d-block d-md-none d-lg-none">
                                    <li className={`${activeTab == 'timeline' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("timeline")}>
                                        <img src={requestIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Timeline</span>
                                    </li>
                                    <li className={`${activeTab == 'about' ? 'tab-bg' : ''} px-2 cursor-pointer`} onClick={() => setPage("about")}>
                                        <img src={aboutIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">About</span>
                                    </li>
                                    <li className={`${activeTab == 'friends' ? 'tab-bg' : ''} px-2 cursor-pointer`} onClick={() => setPage("friends")}>
                                        <img src={friendsIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Friends</span>
                                    </li>
                                    {/* <li className={`${activeTab == 'request' ? 'tab-bg' : ''} px-2 cursor-pointer`} onClick={() => setPage("request")}>
                                        <img src={requestIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Requests</span>
                                    </li> */}
                                    <li className={`${activeTab == 'post' ? 'tab-bg' : ''} px-2 cursor-pointer`} onClick={() => setPage("post")}>
                                        <img src={postIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Posts</span>
                                    </li>
                                    <li className={`${activeTab == 'search' ? 'tab-bg' : ''} px-2 cursor-pointer`} onClick={() => setPage("search")}>
                                        <img src={searchIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Search</span>
                                    </li>
                                </ul>

                                {/* only for desktop responsive */}
                                <div className="row">
                                    <div className="col-lg-5 col-md-5 col-sm-12 col-12 d-none d-md-block d-lg-block">
                                        <ul className="menu-items-list p-0 m-0">
                                            <li className={`${activeTab == 'timeline' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("timeline")}>
                                                <img src={requestIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Timeline</span>
                                            </li>
                                            <li className={`${activeTab == 'about' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("about")}>
                                                <img src={aboutIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">About</span>
                                            </li>
                                            {/* <li className={`${activeTab == 'request' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("request")}>
                                                <img src={requestIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Requests</span>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <div className="col-lg-5 ms-auto col-md-5 col-sm-12 col-12 d-none d-md-block d-lg-block">
                                        <ul className="menu-items-list p-0 m-0">
                                            <li className={`${activeTab == 'friends' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("friends")}>
                                                <img src={friendsIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Friends</span>
                                            </li>
                                            {/* <li className={`${activeTab == 'post' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("post")}>
                                                <img src={postIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Posts</span>
                                            </li> */}
                                            {/* <li className={`${activeTab == 'search' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("search")}>
                                                <img src={searchIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Search</span>
                                            </li> */}
                                            <li className={`${activeTab == 'profileSetting' ? 'tab-bg' : ''} cursor-pointer `} onClick={() => setPage("profileSetting")}>
                                                <img src={friendsIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Profile Settings</span>
                                            </li>
                                            <li className="">
                                                {/* <img src={searchIcon} alt="request-icon" width="20" height="20" className="show-on-small hide-on-large" /> <span className="hide-on-small show-on-large">Search</span> */}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="profile-section hide-on-small show-on-large">
                                {/* <div className="">
                                    {profileImg &&
                                        <img src={profileImg} className="rounded-circle" alt="img" width={120} height={120} />
                                    }
                                </div>
                                <h4 className="m-0">{userName}</h4> */}





                                <div className="text-center">
                                    <div className="">
                                        <img src={profileImg} className="rounded-circle" alt="" width={120} height={120} />
                                    </div>
                                    <div>
                                        <div className="image-upload" style={{ marginBottom: "-20px" }}>
                                            {/* {!profileImgbtn &&
                                                <> */}
                                            <label htmlFor="file-input1">
                                                <i className="fas fa-camera" style={{ backgroundColor: "#d7d7d7", borderRadius: "100px", padding: "5px" }}></i>
                                            </label>
                                            <input className="form-label" id="file-input1" onChange={handleProfileFile} type="file" name="profile_image" accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" />
                                            {/* </>
                                            } */}
                                        </div>
                                    </div>
                                    <h4 className="m-0">{userName}</h4>
                                </div>
                            </div>
                        </div>
                        {profileImgbtn &&
                            <div className="modal-popup text-center">
                                <div className="d-flex justify-content-center mt-4">
                                    <img src={profileImgPreview} className="rounded-circle" alt="" width={180} height={180} />
                                </div>
                                <div className="d-flex justify-content-center mt-5">
                                    <button className="btn btn-theam px-2 py-1" onClick={updateProfileImage}>Upload</button>
                                    <button type="button" className="btn btn-danger px-2 py-1" onClick={handleDiscardImg}>Cancel</button>
                                </div>
                            </div>
                        }

                        {timeline &&
                            <Timeline userInfo={userInfo} token={token} user={user} />
                        }
                        {about &&
                            <About userInfo={userInfo} token={token} />
                        }
                        {friendsList &&
                            <FriendList userInfo={userInfo} token={token} />
                        }
                        {profileSetting &&
                            <ProfileSettings userInfo={userInfo} token={token} />
                        }

                        <div className="d-lg-flex d-md-flex justify-content-between">
                            <div className="col-lg-3 d-none d-md-block d-lg-block">
                                {/* style={{ height: "fit-content" }} */}
                                {/* <ProfileIntro user={user} /> */}
                            </div>
                            <div className="col-lg-6">
                                {/* <div className="profile-menu-components p-4 mx-lg-4 my-sm-2 my-2 my-md-0 my-lg-0 bg-white rounded">
                                    {about &&
                                        <About userInfo={userInfo} token={token} />
                                    }
                                    {friendsList &&
                                        <FriendList userInfo={userInfo} token={token} />
                                    }
                                    {requests &&
                                        <FriendRequest userInfo={userInfo} token={token} />
                                    }
                                    {posts &&
                                        <Posts />
                                    }
                                    {serach &&
                                        <SearchUser userInfo={userInfo} token={token} />
                                    }
                                </div> */}
                            </div>
                            <div className="col-lg-3">
                                {/* style={{ height: "fit-content", minHeight: "260px" }} */}
                                {/* <div className=" p-4 bg-white d-none d-md-block d-lg-block rounded">
                                    <Profile userInfo={userInfo} token={token} />
                                </div> */}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}