import React, { useEffect, useState } from "react";
import user_icon from "../../assets/img/user_icon.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import moreIcon from "../../assets/img/more-btn-icon.png";
import messageIcon from "../../assets/img/message-icon.png";
import { Link } from "react-router-dom";

export default function FriendList({ userInfo, token }) {

    const [freindList, setFreindList] = useState("");
    const [serachFriend, setSerachFriend] = useState("");

    const getFriends = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/get-friend-list`,
                { 
                    headers: { "Authorization": "Bearer " + token },
                    params: { search: serachFriend }
                },
            );
            setFreindList(response?.data);
        } catch (error) {
            console.log(error.response.data.message || 'failed.');
            // toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
            getFriends();
    }, [token, serachFriend]);

    const sendMessage = () => {
        console.log("Message");
    }

    const viewProfile = () => {
        console.log("Profile");
    }

    const viewMore = () => {
        console.log("More");
    }

    return (
        <>
            <div className="d-lg-flex d-md-flex justify-content-between p-4 bg-white rounded">
                <div className="col-12">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 className="m-0">Friends ({freindList ? freindList?.totalCount : "No friends!"})</h6>
                        </div>
                        <div>
                            <form className="" role="search">
                                <input className="form-control me-2" type="search" value={serachFriend} onChange={(e) => setSerachFriend(e.target.value)} placeholder="Search Friends.." aria-label="Search" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-lg-flex d-md-flex justify-content-between p-4 bg-white rounded mt-2">
                <div className="col-12">
                    <ul className="p-0" style={{ listStyle: "none" }}>
                        <div>
                            <h6>Friends</h6>
                        </div>
                        {freindList?.length <= 0 &&
                            <div className="text-center">
                                <h6>No friends!</h6>
                            </div>
                        }
                        {freindList?.list?.map((val, i) => {
                            return (
                                <li className="my-3">
                                    <div className="d-flex align-items-center">
                                        <div style={{width: "70px"}}>
                                            <img src={val?.user?.profile_image ? val?.user?.profile_image : user_icon} alt="User" className="rounded-circle" width={val?.user?.profile_image ? 70 : 75} height="70" />
                                        </div>
                                        <div className="mx-2">
                                                <Link to={`/friend-profile/${val?.user?._id}`} style={{textDecoration: "none"}}><h6 className="mb-4 hover-color">{val?.user?.username}</h6></Link>
                                            {/* <div className="d-flex align-items-center">
                                                <div className="pt-2">
                                                    <button className="btn btn-theam" disabled onClick={() => sendMessage()}>
                                                        <img src={messageIcon} alt="request-icon" width="25" height="25" className="" /> Message
                                                    </button>
                                                </div>
                                                <div className="pt-2 mx-2 hide-on-small show-on-large">
                                                    <button className="btn btn-theam" disabled onClick={() => viewProfile()}>View Profile</button>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className="ms-auto">
                                            <button className="btn" onClick={() => viewMore()}>
                                                <img src={moreIcon} alt="request-icon" width="25" height="25" className="" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
                <Toaster />
            </div>
        </>
    )
}