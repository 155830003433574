import React from "react";
import About from "../About";
import ChangePass from "./ChangePass";

export default function ProfileSettings({ userInfo, token }) {
    return (
        <>
            <div className="d-lg-flex d-md-flex justify-content-between bg-white rounded">
                <div className="col-12">
                    <About userInfo={userInfo} token={token} />
                </div>
            </div>
            <ChangePass token={token} />
        </>
    )
}