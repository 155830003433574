import React, { useEffect, useState } from "react";
import editIcon from "../../assets/img/edit-icon.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Moment from "moment";

export default function About({ userInfo, token }) {

    const [userData, setUserData] = useState(null);
    const infopersonal = {
        firstname: "",
        lastname: "",
        gender: "",
        dob: "",
        phone: "",
        email: "",
        address: "",
        about: "",
        profile_image: "",
    };
    const [personalInfo, setPersonalInfo] = useState(infopersonal);
    const [editUser, setEditUser] = useState(false);
    const [imgupload, setImgupload] = useState({ profile_image: "" });

    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/get-user`,
                { headers: { "Authorization": "Bearer " + token } },
            );
            if (response?.data?.user) {
                setPersonalInfo({
                    firstname: response?.data?.user?.firstname,
                    lastname: response?.data?.user?.lastname,
                    gender: response?.data?.user?.gender,
                    dob: response?.data?.user?.dob,
                    phone: response?.data?.user?.phone,
                    email: response?.data?.user?.email,
                    address: response?.data?.user?.address,
                    about: response?.data?.user?.about,
                    profile_image: response?.data?.user?.profile_image,
                });
                setUserData(response?.data?.user);
            }
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    useEffect(() => {
        if (userInfo) {
            getUser();
        }
    }, [userInfo]);

    const infoHandl = (e) => {
        let info = { ...personalInfo };
        info[e.target.name] = e.target.value;
        setPersonalInfo(info);
    };

    const handleFile = (event) => {
        const profileFile = event.target.files[0]
        setImgupload(profileFile);
    }

    const updateUser = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('firstname', personalInfo.firstname);
            formData.append('lastname', personalInfo.lastname);
            formData.append('gender', personalInfo.gender);
            formData.append('dob', personalInfo.dob);
            formData.append('phone', personalInfo.phone);
            formData.append('email', personalInfo.email);
            formData.append('address', personalInfo.address);
            formData.append('about', personalInfo.about);
            // formData.append('profile_image', imgupload.profile_image);
            formData.append('profile_image', imgupload);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/update-profile`,
                // personalInfo,
                formData,
                { headers: { "Authorization": "Bearer " + token } },
            );
            toast.success(response?.data?.message || "Successfuly Updated.");
            getUser();
            setTimeout(() => {
                setEditUser(false);
            }, 500);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }

    return (
        <div className="d-lg-flex d-md-flex justify-content-between">
            {/* <div className="d-flex justify-content-between align-items-center border-bottom">
                <div>
                    <h6>Personal Info</h6>
                </div>
                <div>
                    <h6>
                        <button className="btn" onClick={() => setEditUser(true)}>
                            <img src={editIcon} alt="edit-icon" width="22" height="22" className="" />
                        </button>
                    </h6>
                </div>
            </div> */}
            <div className="col-12">
                <div className="bg-white rounded">
                    {/* <div className="p-4 border-bottom">
                        <h6 className="m-0">Profile Intro</h6>
                    </div> */}
                    <div className="d-flex justify-content-between align-items-center p-3 border-bottom">
                        <div>
                            <h6 className="m-0">Personal Info</h6>
                        </div>
                        <div>
                            <h6 className="m-0">
                                <button className="btn p-0" onClick={() => setEditUser(true)}>
                                    <img src={editIcon} alt="edit-icon" width="22" height="22" className="" />
                                </button>
                            </h6>
                        </div>
                    </div>
                    <div className="p-4">
                        {!editUser &&
                            <div>
                                <table className="table about-table" style={{ border: "transparent" }}>
                                    <tbody>
                                        <tr>
                                            <th className="title">About Me:</th>
                                            <td className="text">{personalInfo?.about}</td>
                                        </tr>
                                        <tr>
                                            <th className="title">Gender:</th>
                                            <td className="text">{personalInfo?.gender}</td>
                                        </tr>
                                        <tr>
                                            <th className="title">Joined:</th>
                                            <td className="text">{Moment(userData?.createdAt).format('DD-MM-YYYY')}</td>
                                        </tr>
                                        <tr>
                                            <th className="title">Birthday:</th>
                                            <td className="text">{userData?.dob ? Moment(userData?.dob).format('DD-MM-YYYY') : ""}</td>
                                        </tr>
                                        <tr>
                                            <th className="title">Phone Number:</th>
                                            <td className="text">{personalInfo?.phone}</td>
                                        </tr>
                                        <tr>
                                            <th className="title">Email:</th>
                                            <td className="text">{personalInfo?.email}</td>
                                        </tr>
                                        <tr>
                                            <th className="title">Lives in:</th>
                                            <td className="text">{personalInfo?.address}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }

                        {editUser &&
                            <div>
                                <form>
                                    <div className="d-none">
                                        <img src={personalInfo?.profile_image} alt="" width={60} height={60} />
                                        <br />
                                        <input className="form-label" type="file" name="profile_image" accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" onChange={handleFile} />
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-lg-6 col-md-6">
                                            <label className="form-label">First Name</label>
                                            <input type="text" className="form-control" value={personalInfo?.firstname} name="firstname" onChange={(e) => infoHandl(e)} placeholder="First Name"></input>
                                        </div>
                                        <div className="mb-3 col-lg-6 col-md-6">
                                            <label className="form-label">Last Name</label>
                                            <input type="text" className="form-control" value={personalInfo?.lastname} name="lastname" onChange={(e) => infoHandl(e)} placeholder="Last Name"></input>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-lg-6 col-md-6">
                                            <label className="form-label">Gender</label>
                                            <select className="form-control" value={personalInfo?.gender} name="gender" onChange={(e) => infoHandl(e)}>
                                                <option> Select</option>
                                                <option>Male</option>
                                                <option>Female</option>
                                            </select>
                                        </div>
                                        <div className="mb-3 col-lg-6 col-md-6">
                                            <label className="form-label">DOB</label>
                                            <input type="date" className="form-control" value={personalInfo?.dob} name="dob" onChange={(e) => infoHandl(e)}></input>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="mb-3 col-lg-6 col-md-6">
                                            <label className="form-label">Phone Number</label>
                                            <input type="number" className="form-control" value={personalInfo?.phone} name="phone" onChange={(e) => infoHandl(e)} placeholder="Phone Number" />
                                        </div>
                                        <div className="mb-3 col-lg-6 col-md-6">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" disabled value={personalInfo?.email} name="email" onChange={(e) => infoHandl(e)} placeholder="Email" aria-describedby="emailHelp" />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Address</label>
                                        <textarea type="email" className="form-control" value={personalInfo?.address} name="address" onChange={(e) => infoHandl(e)} placeholder="Address" />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">About</label>
                                        <textarea type="text" rows="2" className="form-control" value={personalInfo?.about} name="about" onChange={(e) => infoHandl(e)} placeholder="About" />
                                    </div>
                                    <div className="text-center">
                                        <button type="submit" className="btn btn-theam" onClick={updateUser}>Update</button>
                                        <button type="btn" className="btn btn-theam mx-2" onClick={() => setEditUser(false)}>Cancel</button>
                                    </div>
                                </form>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="mt-3">
                {!editUser &&
                    <div>
                        <table className="table about-table" style={{ border: "transparent" }}>
                            <tbody>
                                <tr>
                                    <th>About Me:</th>
                                    <td>{personalInfo?.about}</td>
                                </tr>
                                <tr>
                                    <th>Gender:</th>
                                    <td>{personalInfo?.gender}</td>
                                </tr>
                                <tr>
                                    <th>Joined:</th>
                                    <td>{Moment(userData?.createdAt).format('DD-MM-YYYY')}</td>
                                </tr>
                                <tr>
                                    <th>Birthday:</th>
                                    <td>{userData?.dob ? Moment(userData?.dob).format('DD-MM-YYYY') : ""}</td>
                                </tr>
                                <tr>
                                    <th>Phone Number:</th>
                                    <td>{personalInfo?.phone}</td>
                                </tr>
                                <tr>
                                    <th>Email:</th>
                                    <td>{personalInfo?.email}</td>
                                </tr>
                                <tr>
                                    <th>Lives in:</th>
                                    <td>{personalInfo?.address}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }

                {editUser &&
                    <div>
                        <form>
                            <div className="d-none">
                                <img src={personalInfo?.profile_image} alt="" width={60} height={60} />
                                <br />
                                <input className="form-label" type="file" name="profile_image" accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG" onChange={handleFile} />
                            </div>
                            <div className="row">
                                <div className="mb-3 col-lg-6 col-md-6">
                                    <label className="form-label">First Name</label>
                                    <input type="text" className="form-control" value={personalInfo?.firstname} name="firstname" onChange={(e) => infoHandl(e)} placeholder="First Name"></input>
                                </div>
                                <div className="mb-3 col-lg-6 col-md-6">
                                    <label className="form-label">Last Name</label>
                                    <input type="text" className="form-control" value={personalInfo?.lastname} name="lastname" onChange={(e) => infoHandl(e)} placeholder="Last Name"></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3 col-lg-6 col-md-6">
                                    <label className="form-label">Gender</label>
                                    <select className="form-control" value={personalInfo?.gender} name="gender" onChange={(e) => infoHandl(e)}>
                                        <option> Select</option>
                                        <option>Male</option>
                                        <option>Female</option>
                                    </select>
                                </div>
                                <div className="mb-3 col-lg-6 col-md-6">
                                    <label className="form-label">DOB</label>
                                    <input type="date" className="form-control" value={personalInfo?.dob} name="dob" onChange={(e) => infoHandl(e)}></input>
                                </div>
                            </div>
                            <div className="row">
                                <div className="mb-3 col-lg-6 col-md-6">
                                    <label className="form-label">Phone Number</label>
                                    <input type="number" className="form-control" value={personalInfo?.phone} name="phone" onChange={(e) => infoHandl(e)} placeholder="Phone Number" />
                                </div>
                                <div className="mb-3 col-lg-6 col-md-6">
                                    <label className="form-label">Email</label>
                                    <input type="email" className="form-control" disabled value={personalInfo?.email} name="email" onChange={(e) => infoHandl(e)} placeholder="Email" aria-describedby="emailHelp" />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Address</label>
                                <textarea type="email" className="form-control" value={personalInfo?.address} name="address" onChange={(e) => infoHandl(e)} placeholder="Address" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">About</label>
                                <textarea type="text" rows="2" className="form-control" value={personalInfo?.about} name="about" onChange={(e) => infoHandl(e)} placeholder="About" />
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-theam" onClick={updateUser}>Update</button>
                                <button type="btn" className="btn btn-theam mx-2" onClick={() => setEditUser(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                }
            </div> */}
            <Toaster />
        </div>
    )
}