import React, { useState } from "react";
import Header from "./Header";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

export default function Signin() {

    const [allValue, setAllValue] = useState({ email: "", password: "" });

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        setAllValue({ ...allValue, [name]: value });
    }

    const navigate = useNavigate();

    const validateEmail = (input) => {
        const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailPattern.test(input);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let save = true;
        if (!allValue.email) {
            save = false;
            toast.error("please enter your email !");
        } else if (!validateEmail(allValue.email)) {
            save = false;
            toast.error("please provide valid email");
        } else if (!allValue.password) {
            save = false;
            toast.error("please enter your password !");
        }
        if (save) {
            var Data = { email: allValue.email, password: allValue.password, };
            try {
                const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/user/user-login`, Data);
                setAllValue({ email: "", password: "" });
                toast.success(response?.data?.message || 'Login successful!');
                localStorage.setItem('AuthToken', JSON.stringify(response?.data?.token));
                Cookies.set('AuthUser', JSON.stringify(response));
                setTimeout(() => {
                    navigate('/');
                }, 500);
            } catch (error) {
                toast.error(error.response.data.message || 'Invalid user or password!');
            }
        }
    }

    return (
        <>
            <Header />
            <div className="container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div className="signup-box bg-white">
                    <div className="text-center pb-4">
                        <h4>Login</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" name="email" value={allValue.firstname} onChange={handleOnChange} placeholder="Email" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input type="password" className="form-control" name="password" value={allValue.password} onChange={handleOnChange} placeholder="password" />
                            </div>
                            <div className="">
                                <button type="submit" className="btn btn-theam form-control">Login</button>
                            </div>
                        </div>
                    </form>
                </div>
                <Toaster />
            </div>
        </>
    )
}