import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import SearchUser from "../SearchUser";
export default function SearchPage() {
    const [token, setToken] = useState("");
    const [navLeft, setNavLeft] = useState(false);
    const [userData, setUserData] = useState("");
    const [serachItem, setSerachItem] = useState("");
    const [isDropdownVisible, setDropdownVisible] = useState(true);
    const formRef = useRef(null);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    // const handleClickOutside = (event) => {
    //     if (formRef.current && !formRef.current.contains(event.target)) {
    //         setDropdownVisible(false);
    //     }
    // };
    
    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);
    let userInfo;
    if (Cookies.get("AuthUser")) {
        userInfo = JSON.parse(Cookies.get("AuthUser"));
    }

    let userToken;
    useEffect(() => {
        userToken = localStorage.getItem('AuthToken');
        if (userToken) {
            userToken = userToken.replace(/^"|"$/g, '');
            setToken(userToken);
        }
        getUser();
    }, [userToken]);
    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/get-user`,
                { headers: { "Authorization": "Bearer " + token } },
            );
            setUserData(response?.data?.user);
        } catch (error) {
            toast.error(error.response.data.message || 'failed.');
        }
    }
    const handleBlur = (e) => {
        if (dropdownRef.current && dropdownRef.current.contains(e.relatedTarget)) {
            return;
        }
        setDropdownVisible(true);
    };
    const handleHeader=()=>{
           navigate('/');
    }
    return (
        <>
            <div className="d-flex flex-row p-1">
                <div className="pe-2">
                    <svg viewBox="0 0 1024 1024" onClick={handleHeader} width="25px" height="30px" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill="#9A9FBF" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"></path><path fill="#9A9FBF" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"></path></g></svg>
                </div>
                <form ref={formRef} className="" role="search" style={{ width: "450px"}}>
                    <input
                        className="form-control header-input"
                        type="text"
                        placeholder="Search here people.."
                        aria-label="Search"
                        onFocus={() => setDropdownVisible(true)}
                        onBlur={handleBlur}
                        // onBlur={() => setDropdownVisible(false)}
                        value={serachItem}
                        onChange={(e) => setSerachItem(e.target.value)}
                        style={{height:"30px" }}
                    />
                    <div ref={dropdownRef} className={`search-dropdown-content ${isDropdownVisible ? 'visible' : ''} rounded p-4`} tabIndex="-1">
                        {isDropdownVisible &&
                           
                            <SearchUser userInfo={userInfo} token={token} serachItem={serachItem} setSerachItem={setSerachItem} />
                        }
                    </div>
                </form>
            </div>
        </>
    )
}