import React from "react";
import ProfileIntro from "../profile/ProfileIntro";
import UserFeed from "../UserFeed";
import Profile from "../Profile";
import UserPhotos from "../UserPhotos";

export default function Timeline({ userInfo, token, user}) {
    return (
        <div className="d-lg-flex d-md-flex justify-content-between">
            <div className="col-lg-3 d-none d-md-block d-lg-block">
                <ProfileIntro user={user} />
            </div>
            <div className="col-lg-6">
                <UserFeed userInfo={userInfo} token={token} user={user}/>
            </div>
            <div className="col-lg-3">
                <UserPhotos/>
                {/* <Profile userInfo={userInfo} token={token} /> */}
            </div>
        </div>
    )
}